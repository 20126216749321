import 'regenerator-runtime'

import { createRouter, createWebHistory } from 'vue-router'
import trackingUtm from '@/libs/middlewares/trackingUtm'
import ProRoutes from '@/routes/pro-routes'
import { afterEach, beforeEach, scrollBehavior } from './common-utils'

const router = createRouter({
    history: createWebHistory(),
    routes: ProRoutes,
    scrollBehavior: scrollBehavior
})

router.beforeEach(trackingUtm)
router.afterEach(afterEach)
router.beforeEach(beforeEach)

export default router
